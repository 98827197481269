import React, { useEffect } from "react";
import Config from "config/themeConfiguration";
import {
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBContainer,
  MDBBox,
  MDBCol,
  MDBTypography,
} from "mdbreact";
import { useLocation } from "react-router-dom";
const defaultHero =
  "https://primalighting.joladev.com/wp-content/uploads/2015/12/g-40-1024x576.jpg";

const SubmenuLightningFixture = (props) => {
  const { menuItems, activeCategory } = props;
  // menuItems.sort((a, b) => a.position - b.position);
  // const firstColumn = menuItems.slice(0, menuItems.length / 2);
  // const secondColumn = menuItems.slice(menuItems.length / 2, menuItems.length);
  let heroImage = activeCategory.additional_image
    ? `${Config.magento_url}media/catalog/category/${activeCategory.additional_image}`
    : defaultHero;

  return (
    <MDBBox className="submenu">
      <MDBContainer fluid>
        <MDBRow>
          <MDBCol lg="5" className="pages-part">
            <MDBBox className="submenu-title">
              <MDBTypography tag="h6" variant="h6">
                {activeCategory.name}
              </MDBTypography>
            </MDBBox>
            <MDBRow>
              {
                <>
                  <MDBCol md="6">
                    <ul>
                      <MDBNavItem
                        style={{
                          marginTop: "20px",
                          marginBottom: "15px",
                          fontWeight: 900,
                          fontSize: "14px",
                          color: "#49494f",
                        }}
                      >
                        Pendants
                      </MDBNavItem>
                      {menuItems.map((item, index) => {
                        if (
                          item.name === "Interchangeable Pendants" ||
                          item.name === "RLM Pendants" ||
                          item.name === "Imported Pendant Lighting" ||
                          item.name === "Made in USA Pendants"
                        ) {
                          return (
                            <MDBNavItem
                              style={{ marginLeft: "15px" }}
                              key={`first-column-${item.url_key}-${item.id}`}
                            >
                              {/* 
                                <MDBNavLink  to={
                                  item.id === 64 ||
                                  item.id === 65 ||
                                  item.id === 122
                                      ? `/${item.url_key}`
                                      : `/${item.url_path}`
                                }>{item.name}</MDBNavLink> */}

                              <a
                                href={
                                  item.id === 64 ||
                                  item.id === 65 ||
                                  item.id === 122
                                    ? `/${item.url_key}`
                                    : `/${item.url_path}`
                                }
                              >
                                {item.name}
                              </a>
                            </MDBNavItem>
                          );
                        }
                      })}
                    </ul>
                  </MDBCol>
                  <MDBCol md="6">
                    <ul>
                      <MDBNavItem
                        style={{
                          marginTop: "20px",
                          marginBottom: "15px",
                          fontWeight: 900,
                          fontSize: "14px",
                          color: "#49494f",
                        }}
                      >
                        Wall Mounted
                      </MDBNavItem>
                      {menuItems.map((item, index) => {
                        // console.log("menu items id", item.id );
                        // console.log("menu items name",  item.name );
                        if (
                          item.name === "ADA Wall Sconce" ||
                          item.name === "Non ADA Wall Lights" ||
                          item.name === "Bathroom Vanities"
                        ) {
                          return (
                            <MDBNavItem
                              style={{ marginLeft: "15px" }}
                              key={`first-column-${item.url_key}-${item.id}`}
                            >
                              {/* <MDBNavLink  to={
                                  item.id === 64 ||
                                  item.id === 65 ||
                                  item.id === 122
                                      ? `/${item.url_key}`
                                      : `/${item.url_path}`
                                }>{item.name}</MDBNavLink> */}
                              <a
                                href={
                                  item.id === 64 ||
                                  item.id === 65 ||
                                  item.id === 122
                                    ? `/${item.url_key}`
                                    : `/${item.url_path}`
                                }
                              >
                                {item.name}
                              </a>
                            </MDBNavItem>
                          );
                        }
                      })}
                    </ul>
                  </MDBCol>
                  <MDBCol md="6">
                    <ul>
                      <MDBNavItem
                        style={{
                          marginTop: "20px",
                          marginBottom: "15px",
                          fontWeight: 900,
                          fontSize: "14px",
                          color: "#49494f",
                        }}
                      >
                        Ceiling Mounted
                      </MDBNavItem>
                      {menuItems.map((item, index) => {
                        // console.log("menu items id", item.id );
                        // console.log("menu items name",  item.name );
                        if (
                          item.name === 'Less Than 12" Diameter' ||
                          item.name === 'Between 12"-18" Diameter' ||
                          item.name === 'Greater Than 18" Diameter'
                        ) {
                          return (
                            <MDBNavItem
                              style={{ marginLeft: "15px" }}
                              key={`first-column-${item.url_key}-${item.id}`}
                            >
                              {/* <MDBNavLink  to={
                                  item.id === 64 ||
                                  item.id === 65 ||
                                  item.id === 122
                                      ? `/${item.url_key}`
                                      : `/${item.url_path}`
                                }>{item.name}</MDBNavLink> */}
                              <a
                                href={
                                  item.id === 64 ||
                                  item.id === 65 ||
                                  item.id === 122
                                    ? `/${item.url_key}`
                                    : `/${item.url_path}`
                                }
                              >
                                {item.name}
                              </a>
                            </MDBNavItem>
                          );
                        }
                      })}
                    </ul>
                  </MDBCol>
                  <MDBCol>
                    <ul>
                      <MDBNavItem
                        style={{
                          marginTop: "20px",
                          marginBottom: "15px",
                          fontWeight: 900,
                          fontSize: "14px",
                          color: "#49494f",
                        }}
                      ></MDBNavItem>
                      {menuItems.map((item, index) => {
                        // console.log("menu items id", item.id );
                        // console.log("menu items name",  item.name );
                        if (
                          item.name === "Recessed Lights" ||
                          item.name === "Lighted Mirrors" ||
                          item.name === "Linear LED Fixtures" ||
                          item.name === "Interchangeable Track Heads" ||
                          item.name === "Display / Picture Lights"
                        ) {
                          return (
                            <MDBNavItem
                              style={{ fontWeight: 900, color: "#49494f" }}
                              key={`first-column-${item.url_key}-${item.id}`}
                            >
                              {/* <MDBNavLink style={{fontWeight:900, color:"#49494f",  fontSize:"13px"}} to={
                                item.id === 64 ||
                                item.id === 65 ||
                                item.id === 122
                                    ? `/${item.url_key}`
                                    : `/${item.url_path}`
                              }>{item.name}</MDBNavLink> */}
                              <a
                                style={{
                                  fontWeight: 900,
                                  color: "#49494f",
                                  fontSize: "13px",
                                }}
                                href={
                                  item.id === 64 ||
                                  item.id === 65 ||
                                  item.id === 122
                                    ? `/${item.url_key}`
                                    : `/${item.url_path}`
                                }
                              >
                                {item.name}
                              </a>
                            </MDBNavItem>
                          );
                        }
                      })}
                    </ul>
                  </MDBCol>
                </>
              }
            </MDBRow>
            <MDBBox className="view-all-box d-flex">
              {/* <a href="#">view all</a> */}
            </MDBBox>
          </MDBCol>
          <MDBCol lg="7">
            <MDBBox className="submenu-right">
              <MDBRow>
                <MDBCol md="4">
                  <MDBBox className="product-image d-flex align-items-center justify-content-center mt-0 pt-0">
                    {activeCategory.image ? (
                      <img
                        src={`${activeCategory.image}`}
                        alt="thumbnailImage"
                        className="img-fluid"
                      />
                    ) : (
                      <></>
                    )}
                  </MDBBox>
                </MDBCol>
                <MDBCol md="8" className="p-0">
                  <MDBBox
                    className="product-back"
                    style={{
                      background: `url(${heroImage})`,
                    }}
                  ></MDBBox>
                </MDBCol>
              </MDBRow>
            </MDBBox>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBBox>
  );
};

export default SubmenuLightningFixture;
