import React, {useEffect, useState} from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBtn, MDBIcon, MDBInput
} from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import Toolbar from "./toolbar";
import Listing from "./listing";
import CheckButton from "core/components/checkButton/checkButton";
import {Checkbox, FormControlLabel} from "@material-ui/core";

import {FormGroup, Input} from "reactstrap";
import {NoResult} from "./NoResult";
import queryString from "query-string";
import {useHistory} from "react-router-dom";
import _ from "lodash";


const Category = ({ toolbarProps, listingProps, totalNumOfItems, setAvailabilityFilter, setClearanceFilter, queryProps, loading}) => {
  const history = useHistory();
   let search = queryString.parse(history.location.search);

  const [toSendQty, setToSendQty] = useState(false);


  /************************** handle filterClearAll, setFilterClearAll *************************/
  let result = false;
  const historyFilterClearAll = Object.values(search).map(value =>
  {

   if( value === "true" ) {
      result = true;
   }
   return result;
  })
  /************************** handle filterClearAll, setFilterClearAll *************************/
  const [filterClearAll, setFilterClearAll] = useState(historyFilterClearAll.pop() === undefined || historyFilterClearAll.pop() ===false ? false : true);
  const [revileQty, setRevileQty] = useState(historyFilterClearAll.pop() === undefined || historyFilterClearAll.pop() ===false ? false : true);

  const urlHistoryQtyChecked = {
    checked5: search.checked5 === 'true' ? true : false,
    checked10: search.checked10 === 'true' ? true : false,
    checked20: search.checked20 === 'true' ? true : false,
    checked50: search.checked50 === 'true' ? true : false,
    checked100: search.checked100 === 'true' ? true : false,
    checked200: search.checked200 === 'true' ? true : false
  }
  const initialStateQtyChecked = {
    checked5: false,
    checked10: false,
    checked20: false,
    checked50: false,
    checked100: false,
    checked200: false
  }
  const [stateQtyChecked, setStateQtyChecked] = React.useState(urlHistoryQtyChecked ? urlHistoryQtyChecked : initialStateQtyChecked);
  const urlHistoryStateQty =  {
    checked5: search.checked5 === 'true' ? "5" : "",
    checked10: search.checked10 === 'true' ? "10" : "",
    checked20: search.checked20 === 'true' ? "20" : "",
    checked50: search.checked50 === 'true' ? "50" : "",
    checked100: search.checked100 === 'true' ? "100" : "",
    checked200: search.checked200 === 'true' ? "200" : ""
  }
  const initialStateQty = {
    checked5: "",
    checked10:"",
    checked20:"",
    checked50:"",
    checked100:"",
    checked200:""
  }

  const [stateQty, setStateQty] = React.useState(urlHistoryStateQty ? urlHistoryStateQty : initialStateQty);

  const handleChangeQTY = (event) => {
    search = {
      ...search,
      ...stateQtyChecked, [event.target.id]: event.target.checked ,
      page: 1,
    };
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify(search),
    });
     setStateQty(event.target.checked === true ? {...stateQty, [event.target.id]: event.target.value}  : {...stateQty, [event.target.id]:""});
     setStateQtyChecked({ ...stateQtyChecked, [event.target.id]: event.target.checked })

  };

  const handleChangeQTYFilter= (qtyFilter) => {
          search = {
            ...search,
            ...stateQtyChecked, [qtyFilter]:false ,
            page: 1,
          };
          history.replace({
            pathname: history.location.pathname,
            search: queryString.stringify(search),
          });
          setStateQty({...stateQty, [qtyFilter]:""})
          setStateQtyChecked({...stateQtyChecked, [qtyFilter]:false})
  }

  const handleClearFilter = (event) => {
    search = {
      ...search,
      ...stateAvailaability, ark_web_available_1: false, ark_web_available_2:false, ark_web_available_4:false, ark_web_available_6:false,
      ...initialStateQtyChecked,
      ...stateArkCloseout, ark_closeout: false,
      page: 1,
    };
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify(search),
    });
    setStateQty(initialStateQty)
    setClearanceDisableRule(initialClearanceDisableRule)
    setStateAvailaabilityValue(initialStateAvailabilityValue)
    setStateAvailaability(initialStateAvabilability)
    setStateQtyChecked(initialStateQtyChecked)
    setStateArkCloseout(initialArk_closeout);
    setClearanceFilter(initialArk_closeout)
    setFilterClearAll(false)

  }
  useEffect(() => {

    if (Object.keys(search).length === 0) {
      setStateQty(initialStateQty)
      setStateAvailaabilityValue(initialStateAvailabilityValue)
      setStateAvailaability(initialStateAvabilability)
      setStateQtyChecked(initialStateQtyChecked)
      setStateArkCloseout(initialArk_closeout);
      setClearanceFilter(initialArk_closeout)
      setFilterClearAll(false)
      setClearanceDisableRule(false)

    }

  }, [Object.keys(search).length]);


  /************************* Availability ************************/
  const urlHistoryStateAvailability =  {
    ark_quantityavailable: search.ark_quantityavailable === 'true' ? true: false,
    ark_web_available_1: search.ark_web_available_1 === 'true' ? true: false,
    ark_web_available_2: search.ark_web_available_2 === 'true' ? true: false,
    ark_web_available_4: search.ark_web_available_4 === 'true' ? true: false,
    ark_web_available_6: search.ark_web_available_6 === 'true' ? true: false,
    // ark_web_available_14: false,
  }
  const initialStateAvabilability = {
    ark_quantityavailable: false,
    ark_web_available_1: false,
    ark_web_available_2: false,
    ark_web_available_4: false,
    ark_web_available_6: false,
   // ark_web_available_14: false,
  }
  const [stateAvailaability, setStateAvailaability] = React.useState(urlHistoryStateAvailability ? urlHistoryStateAvailability : initialStateAvabilability);
  const urlHistoryStateAvailabilityValue =  {
    ark_quantityavailable: search.ark_quantityavailable === 'true' ? "": "",
    ark_web_available_1: search.ark_web_available_1 === 'true' && search.ark_closeout !=="true" ? "1-2 week": "",
    ark_web_available_2: search.ark_web_available_2 === 'true' ? "2-4 week": "",
    ark_web_available_4: search.ark_web_available_4 === 'true' ? "4-6 week": "",
    ark_web_available_6: search.ark_web_available_6 === 'true' ? "6-14 week": "",
    // ark_web_available_14: false,
  }
  const initialStateAvailabilityValue = {
    ark_quantityavailable: "",
    ark_web_available_1: "",
    ark_web_available_2: "",
    ark_web_available_4: "",
    ark_web_available_6: "",
  //  ark_web_available_14: ""

  }
  const initialStateClearanceAvailabilityValue = {
    ark_quantityavailable: "",
    ark_web_available_1: true,
    ark_web_available_2: "",
    ark_web_available_4: "",
    ark_web_available_6: "",
  //  ark_web_available_14: ""

  }
  const [stateAvailaabilityValue, setStateAvailaabilityValue] = React.useState(urlHistoryStateAvailabilityValue ? urlHistoryStateAvailabilityValue : initialStateAvailabilityValue);



  const handleChangeAvailaability = (event) => {

    search = {
      ...search,
      ...stateAvailaability, [event.target.id]: event.target.checked,

      page: 1,
    };
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify(search),
    });



     setFilterClearAll(true)
     setStateAvailaabilityValue(event.target.checked === true ? {...stateAvailaabilityValue, [event.target.id]: event.target.value}  : {...stateAvailaabilityValue, [event.target.id]:""})
     setStateAvailaability( { ...stateAvailaability, [event.target.id]: event.target.checked } );

    Object.keys(stateAvailaability).forEach(key => {
      if (stateAvailaability[key] === undefined) {
        delete stateAvailaability[key];
      }
    });


    if(_.isEqual({ ...stateAvailaability, [event.target.id]: event.target.checked }, initialStateAvabilability)) {
      search = {
        ...search,
        ...initialStateQtyChecked,
        page: 1,
      };
      history.replace({
        pathname: history.location.pathname,
        search: queryString.stringify(search),
      });
      setStateQtyChecked(initialStateQtyChecked)
      setStateQty(initialStateQty)
      setFilterClearAll(false)
    }

  };
  const handleFilterAvailability = (stateAvailaabilityFilter) => {

    search = {
      ...search,
      ...stateAvailaability, [stateAvailaabilityFilter]:false,

      page: 1,
    };
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify(search),
    });
      setStateAvailaability( { ...stateAvailaability, [stateAvailaabilityFilter]:false } );
      setStateAvailaabilityValue( { ...stateAvailaabilityValue, [stateAvailaabilityFilter]:"" } );

    Object.keys(stateAvailaability).forEach(key => {
      if (stateAvailaability[key] === undefined) {
        delete stateAvailaability[key];
      }
    });


    if(_.isEqual({ ...stateAvailaability, [stateAvailaabilityFilter]:false }, initialStateAvabilability)) {
      search = {
        ...search,
        ...initialStateQtyChecked,
        page: 1,
      };
      history.replace({
        pathname: history.location.pathname,
        search: queryString.stringify(search),
      });
      setStateQtyChecked(initialStateQtyChecked)
      setStateQty(initialStateQty)
      setFilterClearAll(false)
    }
  }
  /************************ Availability *************************/



/******************** closeout ********************/
  const urlHistoryArk_closeout = search.ark_closeout === "true" ? {ark_closeout: true} : {ark_closeout: false};

  const initialArk_closeout = {ark_closeout: false};
  const initialClearanceDisableRule = false;
  const initialArk_closeoutInChecked = search.ark_closeout === "true" ? true : false;
  const [clearanceDisableRule, setClearanceDisableRule] = useState(search.ark_closeout === "true" ? true : initialClearanceDisableRule)

  const [stateArkCloseout, setStateArkCloseout] = useState(urlHistoryArk_closeout ? urlHistoryArk_closeout : initialArk_closeout )
  const [stateArkCloseoutChecked, setStateArkCloseoutChecked] = useState(initialArk_closeoutInChecked)
  const handleCloseooutResult = (event) => {
    search = {
      ...search,
      ...stateAvailaability, ark_web_available_1: event.target.checked, ark_web_available_2:false, ark_web_available_4:false, ark_web_available_6:false,
      ...stateArkCloseout, [event.target.id]: event.target.checked,
      page: 1,
    };
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify(search),
    });
     setFilterClearAll(true)
     setClearanceDisableRule(event.target.checked)


   // // console.log("closeout value", value)
     setStateAvailaability({...stateAvailaability, ark_web_available_1: event.target.checked,  ark_web_available_2:false, ark_web_available_4:false, ark_web_available_6:false} );
     setStateArkCloseout({...stateArkCloseout, [event.target.id]: event.target.checked})
    setClearanceFilter({ [event.target.id]: event.target.checked})
     setStateAvailaabilityValue(initialStateAvailabilityValue)

    Object.keys(stateAvailaability).forEach(key => {
      if (stateAvailaability[key] === undefined) {
        delete stateAvailaability[key];
      }
    });


    if(_.isEqual({...stateAvailaability, ark_web_available_1: event.target.checked,  ark_web_available_2:false, ark_web_available_4:false, ark_web_available_6:false} , initialStateAvabilability)) {
      search = {
        ...search,
        ...initialStateQtyChecked,
        page: 1,
      };
      history.replace({
        pathname: history.location.pathname,
        search: queryString.stringify(search),
      });
      setStateQtyChecked(initialStateQtyChecked)
      setStateQty(initialStateQty)
      setFilterClearAll(false)
    }



  }


  const handleCloseoutFilter = (value) => {

    search = {
      ...search,
      ...stateAvailaability, ark_web_available_1: false, ark_web_available_2:false, ark_web_available_4:false, ark_web_available_6:false,
      ...value,

      page: 1,
    };
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify(search),
    });

    setStateAvailaability({...stateAvailaability, ark_web_available_1: false, ark_web_available_2:false, ark_web_available_4:false, ark_web_available_6:false })
    setStateArkCloseout(value)
    setStateArkCloseoutChecked({...stateArkCloseoutChecked, value})
    setClearanceFilter(value)
    setClearanceDisableRule(initialClearanceDisableRule)

    Object.keys(stateAvailaability).forEach(key => {
      if (stateAvailaability[key] === undefined) {
        delete stateAvailaability[key];
      }
    });


    if(_.isEqual({...stateAvailaability, ark_web_available_1: false, ark_web_available_2:false, ark_web_available_4:false, ark_web_available_6:false} , initialStateAvabilability)) {
      search = {
        ...search,
        ...initialStateQtyChecked,
        page: 1,
      };
      history.replace({
        pathname: history.location.pathname,
        search: queryString.stringify(search),
      });
      setStateQtyChecked(initialStateQtyChecked)
      setStateQty(initialStateQty)
      setFilterClearAll(false)
    }


  }

  /******************** closeout ********************/
  useEffect(() => {


    let arr = Object.values(stateAvailaability);
    let catchvalue = false;
    arr.filter(element => {
      if(element) {return catchvalue = true}
    });
   // console.log("element", catchvalue)
    setRevileQty(catchvalue)

  }, [stateAvailaability]);

  /************** stateQty ******************/
  useEffect(() => {
    let arr = Object.values(stateQty);
    var results = arr.filter(element => {
      if (Object.keys(element).length !== 0) {
        return true;
      }

      return false;
    });

    var math = Math.min(...results);
    math = math === Infinity ? 1 :  math;

    setToSendQty(math)
  }, [stateQty]);
  /************** sets availability filter amount\qty ******************/
  useEffect(() => {
    var test = {};

    for (var [key, value] of Object.entries(stateAvailaability)) {
      if (value && (toolbarProps?.name !== "Made in USA Pendants" || key !== "ark_web_available_6")) {
        test[key] = toSendQty
      }
    }

    setAvailabilityFilter(test)

  }, [toSendQty,stateQty,stateAvailaability]);






  return (
    <MDBContainer fluid className="header-padding-top plp-wrapper">
      <MDBRow className="d-flex">
        <MDBCol size="12">
          <MDBContainer>
            <Breadcrumbs className={`mt-3`} />
          </MDBContainer>
        </MDBCol>
        <MDBCol className="products-header border-top">
          <Toolbar
            {...toolbarProps}
            totalNumOfItems={totalNumOfItems}
            isBottom={false}
          />
        </MDBCol>
      </MDBRow>
      {toolbarProps?.name &&
          ( toolbarProps?.name === "ADA Wall Sconce" ||
              toolbarProps?.name === "Bathroom Vanities" ||
              toolbarProps?.name === "Imported Pendant Lighting" ||
              toolbarProps?.name === "Non ADA Wall Lights" ||
              toolbarProps?.name === "Made in USA Pendants" ||
              toolbarProps?.name === "Lighted Mirrors" ||
              toolbarProps?.name === "Between 12\"-18\" Diameter" ||
              toolbarProps?.name === "Greater Than 18\" Diameter" ||
              toolbarProps?.name === "Less Than 12\" Diameter"
          )
          && (
          <MDBRow className={"d-flex products-header border-bottom"} >
          <MDBContainer>
          <MDBRow className={"d-flex "} >
              <MDBCol className=" col-sm-12"  style={{zIndex:"6"}}>
                <MDBDropdown className=" fluid shadow-0 float-left mr-5 left-filter-availability">
                  <MDBDropdownToggle tag='a' style={{ maxWidth:"100%", width: "100%", textTransform:"uppercase", display:"block", fontFamily:"SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif"}}  color='tertiary' rippleColor='light' className="shadow-0 py-5" >Availability<MDBIcon className="ml-3" icon='caret-down' /> </MDBDropdownToggle>
                  <MDBDropdownMenu className="leftBeforeQty" data-mdb-dropdown-init data-mdb-ripple-init ><FormGroup>
                    <MDBDropdownItem ><FormControlLabel  control={<CheckButton size="large" id="ark_web_available_1" value="1-2 weeks" checked={stateAvailaability.ark_web_available_1}  onChange={handleChangeAvailaability} type={"checkbox"} /> } label="" />1-2 weeks</MDBDropdownItem>
                    <MDBDropdownItem ><FormControlLabel  control={<CheckButton size="large" disabled={clearanceDisableRule ?? true} id="ark_web_available_2" value="2-4 weeks" checked={stateAvailaability.ark_web_available_2} onChange={handleChangeAvailaability}  type={"checkbox"}/> } label="" />2-4 weeks</MDBDropdownItem>
                    <MDBDropdownItem ><FormControlLabel  control={<CheckButton size="large" disabled={clearanceDisableRule ?? true} id="ark_web_available_4" value="4-6 weeks" checked={stateAvailaability.ark_web_available_4} onChange={handleChangeAvailaability} type={"checkbox"} /> } label="" />4-6 weeks</MDBDropdownItem>
                    <MDBDropdownItem ><FormControlLabel  control={<CheckButton size="large" disabled={clearanceDisableRule ?? true} id="ark_web_available_6" value="6-14 weeks" checked={stateAvailaability.ark_web_available_6} onChange={handleChangeAvailaability} type={"checkbox"} /> } label="" />6-14 weeks</MDBDropdownItem>
                    <MDBDropdownItem ><FormControlLabel  control={<CheckButton size="large" id="ark_closeout" value="Limited Inventory" checked={stateArkCloseout.ark_closeout} onChange={handleCloseooutResult} type={"checkbox"} /> } label="" />Clearance</MDBDropdownItem>
                  </FormGroup></MDBDropdownMenu>
                </MDBDropdown>
              {/*</MDBCol>*/}
              {/*<MDBCol className="col-md-2 col-sm-6" style={{zIndex:"6"}}>*/}
                <MDBDropdown className="fluid shadow-0 float-left ml-3" >
                  <MDBDropdownToggle tag='a' style={{ maxWidth:"100%", width: "100%", textTransform:"uppercase", display:"block", fontFamily:"SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif"}}  color='tertiary' rippleColor='light' className="shadow-0 py-5" >Quantity<MDBIcon className="ml-3" icon='caret-down' /> </MDBDropdownToggle>

                  <MDBDropdownMenu className={"rightQTY"} data-mdb-dropdown-init data-mdb-ripple-init ><FormGroup >
                    {!revileQty && (
                        <MDBDropdownItem >Set Availability</MDBDropdownItem>
                    )

                    }
                    <MDBDropdownItem ><FormControlLabel  control={<CheckButton disabled={!revileQty ?? true} size="large" id="checked5" value={5} checked={stateQtyChecked.checked5} onChange={handleChangeQTY} type={"checkbox"} /> } label="" />5+</MDBDropdownItem>
                    <MDBDropdownItem><FormControlLabel control={<CheckButton disabled={!revileQty ?? true} size="large" id="checked10" value={10} checked={stateQtyChecked.checked10} onChange={handleChangeQTY} type={"checkbox"} /> } label="" />10+</MDBDropdownItem>
                    <MDBDropdownItem><FormControlLabel control={<CheckButton disabled={!revileQty ?? true} size="large" id="checked20" value={20} checked={stateQtyChecked.checked20} onChange={handleChangeQTY}  type={"checkbox"} /> } label=""/>20+</MDBDropdownItem>
                    <MDBDropdownItem><FormControlLabel control={<CheckButton disabled={!revileQty ?? true} size="large" id="checked50" value={50} checked={stateQtyChecked.checked50} onChange={handleChangeQTY} type={"checkbox"} /> } label=""  />50+</MDBDropdownItem>
                    <MDBDropdownItem><FormControlLabel control={<CheckButton disabled={!revileQty ?? true} size="large" id="checked100" value={100} checked={stateQtyChecked.checked100} onChange={handleChangeQTY} type={"checkbox"} /> } label=""  />100+</MDBDropdownItem>
                    <MDBDropdownItem><FormControlLabel control={<CheckButton disabled={!revileQty ?? true} size="large" id="checked200" value={200} checked={stateQtyChecked.checked200} onChange={handleChangeQTY} type={"checkbox"} /> } label=""  />200+</MDBDropdownItem>
                  </FormGroup></MDBDropdownMenu>
                </MDBDropdown>

              </MDBCol>
          </MDBRow>
          </MDBContainer>
          </MDBRow>
      )}
      {/*here starts filter*/}


      <MDBRow>
        <MDBCol>
          <MDBContainer>
            {stateArkCloseout.ark_closeout === true && (
                <>
                  <span style={{fontSize:"1.6em", cursor:"pointer" }} className='mx-2 filtersCategory' color='tertiary' rippleColor='light' onClick={(event) => handleCloseoutFilter(urlHistoryArk_closeout ? initialArk_closeout : initialArk_closeout)}>Clearance</span>
                </>
            )
            }
            {stateAvailaability && Object.keys(stateAvailaability).map( ( stateAvailaabilityFilter, i ) =>

                <>
                  {stateAvailaabilityValue[stateAvailaabilityFilter] !== "" && (
                      //
                      <>

                        <span style={{fontSize:"1.6em", cursor:"pointer" }} className='mx-2 filtersCategory' color='tertiary' rippleColor='light' onClick={(event) => {handleFilterAvailability(stateAvailaabilityFilter)}}>{ stateAvailaabilityValue[stateAvailaabilityFilter]}</span>
                      </>
                  )}
                </>
            )}
            {stateQty && Object.keys(stateQty).map( ( qtyFilter,i) =>

                <>

                  {stateQty[qtyFilter] !== "" && (
                      <>
                        <span style={{fontSize:"1.6em", cursor:"pointer"}} className='mx-2 filtersCategory' color='tertiary' rippleColor='light' onClick={(event) => {handleChangeQTYFilter(qtyFilter)}}>+{stateQty[qtyFilter]} </span>
                      </>
                  )}
                </>
            )}
            {filterClearAll && (
                <>
                  <span style={{fontSize:"1.6em", textDecoration:"underline", cursor:"pointer", fontFamily: "Montserrat"}} className='mx-2' color='tertiary' rippleColor='light'  onClick={handleClearFilter} >Clear Filters</span>
                </>
            )}

          </MDBContainer>
        </MDBCol>
      </MDBRow>
      {/*here ends filter*/}
      <MDBRow>
        <MDBCol>
              <MDBContainer>

                <Listing items={listingProps} filteringQtyGrouped={toSendQty} availabilityGroup={stateAvailaability} handleClearFilter={handleClearFilter} loading={loading}/>
                <Toolbar
                    showSorting={false}
                    showPageSize={true}
                    showName={false}
                    {...toolbarProps}
                    totalNumOfItems={totalNumOfItems}
                    isBottom={true}
                />
              </MDBContainer>


        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Category;
