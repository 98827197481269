import React, { useRef } from 'react';
import { MDBCol, MDBContainer, MDBRow, Typography } from "mdbreact";
import Header from "../../../layout/quoteRequest/quote-pdf/header";
import { Image } from "react-bootstrap";
import FooterPdf from "../../../layout/quoteRequest/quote-pdf/footer";
import Logo from "../../../layout/logo";


export const ArkansasSpecSheet = ({ data, isLoggedIn }) => {
    return (
        <html>
            <MDBRow style={{ width: "98%", fontSize: "25em", margin: " auto" }} className="py-5 px-5 wrap-arkansas-specsheet">
                <body>
                    <MDBRow className="px-0 py-0 ml-0 mr-0 "  >
                        <MDBCol size={"12"} className="px-0 py-0 ml-0 mr-0" >
                            <Header className="pb-0 mb-5" />
                        </MDBCol>
                        <MDBCol size={"12"} className="px-0 py-5 ml-0 mr-0" >

                            <h1 style={{ fontSize: "4.5rem" }}>{data.name}</h1>
                            {data?.ark_closeout && data?.ark_closeout === 1 && data?.ark_closeout !== 0 ? (
                                <div style={{ width: "200px", background: "#151521", color: "#ffffff", textAlign: "center" }}>
                                    <p style={{ color: "#ffffff", padding: "5px" }}>Clearance</p>
                                </div>
                            ) : (<></>)}
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="px-0 py-0 ml-0 mr-0 justify-content-between " style={{ gap: "20px" }} >
                        <MDBCol>
                            <MDBRow>
                                <Image style={{ width: "85%", margin: "0 auto" }} src={data?.image?.url} />
                            </MDBRow>
                            <MDBRow>
                                <Typography style={{ fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif", color: "#979797", textDecoration: "italic", fontSize: "1.4rem", lineHeight: "1.8rem" }}><i>All specifications noted on this sheet match the photograph shown. Almost every line item has multiple options. Please contact your sales representative for more details.  UL / cUL Listed and Built to Exacting Commercial Standards</i> </Typography>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol>
                            <MDBRow style={{ borderBottom: "1px solid #e1e1e1" }} className={"pb-5"}>
                                <Typography style={{ fontWeight: 600, color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }}>
                                    ITEM NUMBER: <span style={{ fontWeight: 400, fontFamily: "Montserrat, Tahoma, Geneva, Verdana, sans-serif" }}>{data?.sku}</span>
                                </Typography>
                            </MDBRow>
                            <MDBRow className={"pb-5 pt-5"}> <Typography style={{ fontWeight: 600, color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }} >DESCRIPTION: </Typography></MDBRow>
                            <MDBRow style={{ borderBottom: "1px solid #e1e1e1" }} className={"pb-5 "}> <Typography style={{ color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }}>{data?.ark_custitem_prima_sales_desc}</Typography></MDBRow>
                            {data?.ark_turnaround_code && data.ark_turnaround_code === "S" ? (
                                <MDBRow style={{ borderBottom: "1px solid #e1e1e1" }} className={"pb-5 pt-5"}>
                                    <Typography style={{ fontWeight: 600, color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }}>
                                        SHIPPING CODE: <span style={{ fontWeight: 400, fontFamily: "Montserrat, Tahoma, Geneva, Verdana, sans-serif" }}>SPECIAL ORDER</span>
                                    </Typography>
                                </MDBRow>
                            ) : (<></>)}
                            {/*{isLoggedIn && data?.ark_closeout && data?.ark_closeout === 1 ? (*/}
                            {/*    <MDBRow style={{borderBottom: "1px solid #e1e1e1"}} className={"pb-5 pt-5"}>*/}
                            {/*        <Typography style={{ fontWeight:400, color: "#000000", fontFamily:"SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif", fontSize: "2.4rem"}}>${data?.ark_closeout_price}</Typography>*/}
                            {/*    </MDBRow>*/}
                            {/* ):(<></>)}*/}
                            {/*{isLoggedIn && data?.ark_closeout !== 1 ? (*/}
                            {/*    <MDBRow style={{borderBottom: "1px solid #e1e1e1"}} className={"pb-5 pt-5"}>*/}
                            {/*        <Typography style={{ fontWeight:400, color: "#000000", fontFamily:"SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif", fontSize: "2.4rem"}}>${data?.price_range?.minimum_price?.regular_price?.value}</Typography>*/}
                            {/*    </MDBRow>*/}
                            {/*):(<></>)}*/}
                        </MDBCol>
                    </MDBRow>
                    <MDBCol size={"12"} style={{ borderBottom: "1px solid #e1e1e1" }} className="px-0 py-0 ml-0 mr-0 mb-5 pb-5 mt-5 pt-5">
                        <Typography style={{ width: "100%", fontWeight: 400, color: "#000000", fontFamily: "SangBleuSunriseRegular, Geneva, Verdana, sans-serif", fontSize: "2.4rem" }}>SPECIFICATIONS</Typography>
                    </MDBCol>
                    <MDBCol size={"12"} className="px-0 py-0 ml-0 mr-0 mb-5 pb-5 ">
                        <MDBRow s className="justify-content-between">
                            <MDBCol >
                                {data?.ark_main_color ? (
                                    <MDBRow className="py-3 px-3">
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 600, color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }}>  Main color: </Typography>
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 400, fontFamily: "Montserrat, Tahoma, Geneva, Verdana, sans-serif" }}>{data?.ark_main_color}</Typography>
                                    </MDBRow>) : null}
                                {data?.ark_shade_dimensions ? (
                                    <MDBRow className="py-3 px-3">
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 600, color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }}>  Shade Dimension: </Typography>
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 400, fontFamily: "Montserrat, Tahoma, Geneva, Verdana, sans-serif" }}>{data?.ark_shade_dimensions}</Typography>
                                    </MDBRow>) : null}
                                {data?.ark_material_type ? (
                                    <MDBRow className="py-3 px-3">
                                        <Typography style={{ width: "50%", fontWeight: 600, color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }}> Material Type: </Typography>
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 400, fontFamily: "Montserrat, Tahoma, Geneva, Verdana, sans-serif" }}>{data?.ark_material_type}</Typography>
                                    </MDBRow>) : null}
                                {data?.ark_shade_material ? (
                                    <MDBRow className="py-3 px-3">
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 600, color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }}>Shade Material: </Typography>
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 400, fontFamily: "Montserrat, Tahoma, Geneva, Verdana, sans-serif" }}>{data?.ark_shade_material}</Typography>
                                    </MDBRow>) : null}
                                {data?.ark_primary_finish ? (
                                    <MDBRow className="py-3 px-3">
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 600, color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }}>Finish: </Typography>
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 400, fontFamily: "Montserrat, Tahoma, Geneva, Verdana, sans-serif" }}>{data?.ark_primary_finish}</Typography>
                                    </MDBRow>) : null}
                                {data?.ark_base ? (
                                    <MDBRow className="py-3 px-3">
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 600, color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }}>UL Rating: </Typography>
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 400, fontFamily: "Montserrat, Tahoma, Geneva, Verdana, sans-serif" }}>{data?.ark_custitem_ul_rating}</Typography>
                                    </MDBRow>) : null}
                            </MDBCol>
                            <MDBCol>
                                {data?.ark_power_cord ? (
                                    <MDBRow className="py-3 px-3">
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 600, color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }}>  Power cord: </Typography>
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 400, fontFamily: "Montserrat, Tahoma, Geneva, Verdana, sans-serif" }}>{data?.ark_power_cord}</Typography>
                                    </MDBRow>) : null}
                                {data?.ark_voltage ? (
                                    <MDBRow className="py-3 px-3">
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 600, color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }}>  Voltage: </Typography>
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 400, fontFamily: "Montserrat, Tahoma, Geneva, Verdana, sans-serif" }}>{data?.ark_voltage}</Typography>
                                    </MDBRow>) : null}
                                {data?.ark_bulb_wattage ? (
                                    <MDBRow className="py-3 px-3">
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 600, color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }}> Bulb wattage: </Typography>
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 400, fontFamily: "Montserrat, Tahoma, Geneva, Verdana, sans-serif" }}>{data?.ark_bulb_wattage}</Typography>
                                    </MDBRow>) : null}
                                {data?.ark_base ? (
                                    <MDBRow className="py-3 px-3">
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 600, color: "#000000", fontFamily: "SangBleuSunriseRegular, Tahoma, Geneva, Verdana, sans-serif" }}>Base: </Typography>
                                        <Typography style={{ lineHeight: "1.8rem", width: "50%", fontWeight: 400, fontFamily: "Montserrat, Tahoma, Geneva, Verdana, sans-serif" }}>{data?.ark_base}</Typography>
                                    </MDBRow>) : null}
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </body>
                <div id="footer">
                    <MDBCol size={"12"} className=" mb-3 pb-3 " style={{
                        position: "fixed",
                        bottom: "0rem",
                        left: "0rem",
                        right: "0rem",
                    }}>
                        <MDBRow className={"mb-5  pb-5 "}  >
                            <MDBCol size={"12"} style={{ textAlign: "center", alignItems: "center" }}>
                                <Typography style={{ color: "#1F1E3F" }}> For custom requests, please contact <b>contact@primalighting.joladev.com</b> </Typography>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className={"pt-3 mt-3 px-0 py-0 ml-0 mr-0 pl-0 pr-0"} style={{ width: "98%", textAlign: "center", alignItems: "center", borderTop: "1px solid #e1e1e1" }}>
                            <MDBCol size={"1"}><Logo style={{ textAlign: "center" }} mobileLogo={true} /></MDBCol>
                            <MDBCol className="pt-3" style={{ fontWeight: "400", fontSize: "0.7rem", color: "#49494f", width: "100%" }}>
                                <a rel="noopener noreferrer" target="_blank" href={`tel:479-474-0876`} className="text-decoration-none">
                                    <span className="font-weight-bold">t.</span> 479-474-0876
                                </a>
                                <a rel="noopener noreferrer" target="_blank" href={`mailto:contact@primalighting.joladev.com`} style={{ padding: "0 15px" }} className="text-decoration-none">
                                    <span className="font-weight-bold">e.</span>contact@primalighting.joladev.com
                                </a>
                                <a>business hours 6am - 5pm CT, Monday - Thursday</a>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </div>
            </MDBRow>
        </html>
    )
}